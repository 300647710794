<template>
  <div class="tour">
    <TournmentInfo
      :config="config"
      :loggedin="loggedin"
      :course="course"
      :title="title"
      :data="data"
      :tourinfo="tourinfo"
      :until="until"
      splash="splash"
      splashText="splashText"
      logo="logo"
      overlay="overlay"
      courseStats="courseStats"
      :id="this.$route.query.id"
      :code="this.$route.query.code"
      :isMobile="isMobile"
      :changeReport="changeReport"
      :outVal="outVal"
      :inVal="inVal"
      :totalVal="totalVal"
      :currentStatus="currentStatus"
      :onEnter="onEnter"
      :onWithdraw="onWithdraw"
      :onPay="onPay"
      :PlayerLogged="PlayerLogged"
      :reportData="reportData"
      :filterReports="filterReports"
      :DEFIMAGESAMS3="DEFIMAGESAMS3"
    />

    <div class="heightColorDiv"></div>
    <div class="tourInfo reportSection container tournReportSection">
      <div v-if="title.includes('Score')">
        <div class="TitleArea">
          <span v-if="title.includes('Score')">
            <h3 class="courseDates TitleAreaScore">
              {{ data.course_dates }}
            </h3>
            <h3 class="courseDates lastRealTime-update">
              Last Real Time Update -
              {{ data.last_scores_update_date }} - {{ data.course_city }} Time -
              {{ data.last_scores_update_time }}
              {{ data.last_scores_update_date }}
            </h3>
            <template v-if="showGender == 'Y'">
              <p class="showPgender">
                <span class="multiDot-size-reportgender male">• </span> = Men
                Professional <br />
                <span class="multiDot-size-reportgender female">• </span> =
                Ladies Professional
              </p>
            </template>
          </span>
        </div>
        <b-tabs content-class="mt-3" class="tabsLi">
          <template v-if="data.hide_gross_sb == 'Y'">
            <b-tab title="LEADERBOARD" active title-item-class="nonactive">
              <template v-if="data.stableford == 'Y'">
                <ScoreTeam
                  :data="data"
                  :title="title"
                  :home="false"
                  :config="config"
                  :team="teamEvent"
                  :match="tourinfo.match_play"
                  :code="code"
                  :season="season"
                  :showGender="showGender"
                  :is_qualifier="is_qualifier"
                  :stableford="stableford"
                />
              </template>
              <template v-else>
                <ReportTable
                  :data="data"
                  :title="title"
                  :home="false"
                  :config="config"
                  :team="teamEvent"
                  :match="tourinfo.match_play"
                  :code="code"
                  :season="season"
                  :showGender="showGender"
                  :is_qualifier="is_qualifier"
                  :stableford="stableford"
                />
              </template>
            </b-tab>
            <b-tab title="HOLE-BY-HOLE" title-item-class="nonactive HBH">
              <HBH
                :data="data"
                :previousData="previousData"
                :config="config"
                :title="title"
                :showGender="showGender"
              />
            </b-tab>
          </template>
          <template v-else>
            <template v-if="data.tt_code == '2B'">
              <b-tab
                active
                title="LEADERBOARD"
                title-item-class="nonactive"
              >
                <template v-if="data.stableford == 'Y'">
                  <ScoreTeam
                    :data="data"
                    :title="title"
                    :home="false"
                    :config="config"
                    :team="teamEvent"
                    :match="tourinfo.match_play"
                    :code="code"
                    :season="season"
                    :showGender="showGender"
                    :is_qualifier="is_qualifier"
                    :stableford="stableford"
                  />
                </template>
                <template v-else>
                  <ReportTable
                    :data="data"
                    :title="title"
                    :home="false"
                    :config="config"
                    :team="teamEvent"
                    :match="tourinfo.match_play"
                    :code="code"
                    :season="season"
                    :showGender="showGender"
                    :is_qualifier="is_qualifier"
                    :stableford="stableford"
                  />
                </template>
              </b-tab>
              <b-tab title="HOLE-BY-HOLE" title-item-class="nonactive HBH">
                <HBH
                  :data="data"
                  :previousData="previousData"
                  :config="config"
                  :title="title"
                  :showGender="showGender"
                />
              </b-tab>
            </template>
            <template v-else>
              <b-tab title="LEADERBOARD" active title-item-class="nonactive">
                <ReportTable
                  :data="data"
                  :title="title"
                  :home="false"
                  :config="config"
                  team="N"
                  :match="tourinfo.match_play"
                  :code="code"
                  :season="season"
                  :showGender="showGender"
                  :is_qualifier="is_qualifier"
                  :stableford="stableford"
                />
              </b-tab>
              <b-tab
                title="TEAM"
                v-if="teamEvent === 'Y'"
                title-item-class="nonactive"
              >
                <template v-if="data.stableford == 'Y'">
                  <ScoreTeam
                    :data="data"
                    :title="title"
                    :home="false"
                    :config="config"
                    :team="teamEvent"
                    :match="tourinfo.match_play"
                    :code="code"
                    :season="season"
                    :showGender="showGender"
                    :is_qualifier="is_qualifier"
                    :stableford="stableford"
                  />
                </template>
                <template v-else>
                  <ReportTable
                    :data="data"
                    :title="title"
                    :home="false"
                    :config="config"
                    :team="teamEvent"
                    :match="tourinfo.match_play"
                    :code="code"
                    :season="season"
                    :showGender="showGender"
                    :is_qualifier="is_qualifier"
                    :stableford="stableford"
                  />
                </template>
              </b-tab>
              <b-tab title="HOLE-BY-HOLE" title-item-class="nonactive HBH">
                <HBH
                  :data="data"
                  :previousData="previousData"
                  :config="config"
                  :title="title"
                  :showGender="showGender"
                />
              </b-tab>
            </template>
          </template>
        </b-tabs>
      </div>
      <div v-else>
        <ReportTable
          :data="data"
          :title="title"
          :home="false"
          :config="config"
          :team="team"
          :match="tourinfo.match_play"
          :code="code"
          :season="season"
          :showGender="showGender"
          :is_qualifier="is_qualifier"
          :stableford="stableford"
        />
      </div>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import TournmentInfo from "@/components/tournamentInfo.vue";
import ReportTable from "@/components/reportTable.vue";
import { HBH, ScoreTeam } from "@ocs-software/vue-ocs";
import socketServe from "@/js/socketio-services.js";
export default {
  name: "Tournament",
  components: { ReportTable, HBH, TournmentInfo, ScoreTeam },
  props: ["config", "loggedin", "course", "apiClass", "showGender"],
  data() {
    return {
      data: [],
      tourinfo: [],
      splash: "splash",
      splashText: "splashText",
      logo: "logo",
      overlay: "overlay",
      courseStats: "courseStats",
      until: [],
      id: this.$route.query.id,
      code: this.$route.query.code,
      title: "",
      report: "",
      form: {
        text: ""
      },
      polling: null,
      reportData: {},
      filterReports: {}
    };
  },
  metaInfo() {
    return {
      title: this.tourinfo.full_name,
      meta: [
        { charset: "utf-8" },
        { name: "description", content: this.description }
      ]
    };
  },
  methods: {
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    changeReport: function(event) {
      var report = event.target.getAttribute("title");
      var title = event.target.getAttribute("value");
      (this.report = report), (this.title = title);
      return apiCall
        .report(this.reportTitle)
        .then(({ data }) => {
          this.data = data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          throw error;
        });
    },
    outVal(value) {
      var split = value.split(",");
      var front9 = split.slice(0, 9);
      for (var i = 0; i < front9.length; i++) front9[i] = +front9[i];
      var sum = front9.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
    inVal(value) {
      var split = value.split(",");
      var back9 = split.slice(9, 18);
      for (var i = 0; i < back9.length; i++) back9[i] = +back9[i];
      var sum = back9.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
    totalVal(value) {
      var split = value.split(",");
      for (var i = 0; i < split.length; i++) split[i] = +split[i];
      var sum = split.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
    currentStatus(value, status) {
      if (value === "X") status = "Not Entered";
      else if (value === "W") status = "Withdrawn";
      else if (value === "R") status = "Reserve";
      else if (value === "F") status = "Failed to Qualify";
      else if (value === "E") status = "Entered";
      else if (value === "L") status = "Late Entry";
      else if (value === "Q") status = "Required to Qualify";
      else if (value === "C") status = "Cut";
      return status;
    },
    onEnter: function(user, password, text, code, id, fee) {
      user = sessionStorage.getItem("playerID", "");
      password = sessionStorage.getItem("password", "");
      text = this.form.text;
      code = this.code;
      id = this.id;
      fee = this.tourinfo.entry_fee;
      socketServe.enterSocket(user, password, text, code, id, fee);
      return this.$bvModal.hide("ew");
    },
    onWithdraw: function(user, password, text, code, id, fee) {
      user = sessionStorage.getItem("playerID", "");
      password = sessionStorage.getItem("password", "");
      text = this.form.text;
      code = this.code;
      id = this.id;
      fee = this.tourinfo.entry_fee;
      socketServe.withdrawSocket(user, password, text, code, id, fee);
      return this.$bvModal.hide("ew");
    },
    onPay: function(user, password, code, id, fee) {
      user = sessionStorage.getItem("playerID", "");
      password = sessionStorage.getItem("password", "");
      code = this.code;
      id = this.id;
      fee = this.tourinfo.entry_feeowe;
      socketServe.paySocket(user, password, code, id, fee);
      return this.$bvModal.hide("ew");
    },
    async login() {
      try {
        // First call, await for call to return
        let first_call = await apiCall.tmParamsPlayer(
          this.id,
          this.loggedin.playerID,
          this.code
        );
        this.tourinfo = first_call.data;
        this.until = parseInt(first_call.data.days_away);
        this.showGender = first_call.data.show_gender;
        this.loading = false;
        // Second call, await for call to return
        let second_call = await apiCall.report(this.reportTitle);
        this.data = second_call.data;
        this.loading = false;
      } catch (error) {
        console.log("error in await calls");
        console.log(error);
        throw error;
      }
    },
    async notLogin() {
      try {
        // First call, await for call to return
        let first_call = await apiCall.tmParams(this.id, this.code);
        this.DEFIMAGESAMS3 = first_call.data.tm_params.DEFIMAGESAMS3;
        this.tourinfo = first_call.data;
        this.until = first_call.data.days_away;
        this.showGender = first_call.data.show_gender;
        this.is_qualifier = first_call.data.is_qualifier;
        this.stableford = first_call.data.stableford;
        this.loading = false;
        // Second call, await for call to return
        let second_call = await apiCall.report(this.reportTitle);
        this.data = second_call.data;
        console.log("data");
        console.log(this.data);
        console.log("showGender");
        console.log(this.showGender);
        this.loading = false;
      } catch (error) {
        console.log("error in await calls");
        console.log(error);
        throw error;
      }
    },
    pollData() {
      this.polling = setInterval(() => {
        // console.log("Run refresh");
        this.notLogin();
      }, 30000);
    }
  },
  computed: {
    PlayerLogged: function(player) {
      if (this.loggedin === "" || this.loggedin.status === null)
        player = "guest";
      else player = this.loggedin.playerID;
      this.login();
      return player;
    },
    teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined) team = "Y";
      else team = "N";
      return team;
    },
    currentReport: function(cr) {
      if (this.report === "") {
        if (Array.isArray(this.tourinfo.reports.reports_entry)) {
          cr = this.tourinfo.reports.reports_entry[0].report_url;
        } else cr = this.tourinfo.reports.reports_entry.report_url;
      } else cr = this.report;
      return cr;
    },
    reportTitle: function(url) {
      if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
          this.config.VUE_APP_API +
          "sst" +
          "/cache/" +
          this.apiClass +
          "/" +
          this.$route.query.id +
          "/" +
          this.$route.query.id +
          "-" +
          this.$route.query.code +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.currentReport.indexOf("tmscore") !== -1) {
          var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
          var indNext2 = this.currentReport.indexOf("~", subRep2);
          var repParams = this.currentReport.substr(
            subRep2,
            indNext2 - subRep2
          );
          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
              this.config.VUE_APP_API +
              "sst" +
              "/cache/" +
              this.apiClass +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-" +
              repParams +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
          } else {
            url =
              this.config.VUE_APP_API +
              "sst" +
              "/cache/" +
              this.apiClass +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
          {
            url =
              this.config.VUE_APP_API +
              "sst" +
              "/cache/" +
              this.apiClass +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep = this.currentReport.lastIndexOf("round=") + 6;
          var indNext = this.currentReport.indexOf("~", subRep);
          var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
          url =
            this.config.VUE_APP_API +
            "sst" +
            "/cache/" +
            this.apiClass +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.currentReport.indexOf("tmresult") !== -1) {
          var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
          var indNext1 = this.currentReport.indexOf("~", subRep1);
          var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
          console.log("resNum === ");
          console.log(resNum1);
          url =
            this.config.VUE_APP_API +
            "sst" +
            "/cache/" +
            this.apiClass +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            this.config.VUE_APP_API +
            "sst" +
            "/cache/" +
            this.apiClass +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    }
  },
  mounted() {
    this.title = "Tournament Entries";
    //  if (this.title == "Tournament Entries"
    //  || this.title == "Draw"
    //  || this.title == "Score"
    //  || this.title == "Results") {
    //   console.log("IN")
    //   this.reportTitle();
    //  } else {
    //   this.reportTitle();
    //  }
    if (this.loggedin.status === null || this.loggedin === "") {
      this.notLogin();
    } else {
      this.login();
    }
  }
};
</script>
<style scoped>
.showPgender {
  color: #026638;
  font-weight: 600;
}
span.multiDot-size-reportgender {
  font-size: 30px !important;
  line-height: 0px !important;
  position: relative;
  top: 6px;
}
.female {
  color: #e33;
}
.male {
  color: #037;
}
::v-deep li.nav-item.nonactive > a {
  color: #f2af15 !important;
}
::v-deep .nav-tabs .nav-link {
  margin-bottom: -2px;
  border: 0px solid transparent !important;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #ffffff !important;
  background-color: #026638;
  border-color: #026638 #026638 #026638;
}
::v-deep .mt-3,
.my-3 {
  margin-top: 0rem !important;
}
::v-deep .tabsLi > div > ul > li {
  margin-right: 20px;
}
.reportSection {
  padding: 0px !important;
}
.tourInfo.reportSection.container.tournReportSection {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.heightColorDiv {
  background: #006737;
  height: 30px;
  display: none;
}
.tourInfo {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 2em;
  color: #6c6c6c;
  font-size: 13px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
h3.courseDates {
  color: #026034;
  font-weight: bold;
  font-size: 14px;
  text-align: left !important;
}
.TitleArea {
  margin-bottom: 0px;
  margin-top: 5px;
  display: none;
}
.TitleAreaScore {
  margin-bottom: 25px;
  margin-top: 40px;
}
.lastRealTime-update {
  margin-top: 30px;
  margin-bottom: 2em;
}
@media only screen and (max-width: 500px) {
  ::v-deep .tabsLi > div > ul > li {
    margin-right: 0px;
    display: contents !important;
  }
  ::v-deep li.nav-item.nonactive.HBH {
    display: none !important;
  }
}
</style>
